import _stream from "stream";
import _zlib from "zlib";
import _mimicResponse from "mimic-response";
var exports = {};
const {
  Transform,
  PassThrough
} = _stream;
const zlib = _zlib;
const mimicResponse = _mimicResponse;

exports = response => {
  const contentEncoding = (response.headers["content-encoding"] || "").toLowerCase();

  if (!["gzip", "deflate", "br"].includes(contentEncoding)) {
    return response;
  } // TODO: Remove this when targeting Node.js 12.


  const isBrotli = contentEncoding === "br";

  if (isBrotli && typeof zlib.createBrotliDecompress !== "function") {
    response.destroy(new Error("Brotli is not supported on Node.js < 12"));
    return response;
  }

  let isEmpty = true;
  const checker = new Transform({
    transform(data, _encoding, callback) {
      isEmpty = false;
      callback(null, data);
    },

    flush(callback) {
      callback();
    }

  });
  const finalStream = new PassThrough({
    autoDestroy: false,

    destroy(error, callback) {
      response.destroy();
      callback(error);
    }

  });
  const decompressStream = isBrotli ? zlib.createBrotliDecompress() : zlib.createUnzip();
  decompressStream.once("error", error => {
    if (isEmpty && !response.readable) {
      finalStream.end();
      return;
    }

    finalStream.destroy(error);
  });
  mimicResponse(response, finalStream);
  response.pipe(checker).pipe(decompressStream).pipe(finalStream);
  return finalStream;
};

export default exports;